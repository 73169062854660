import $ from 'jquery'
import Backbone from 'backbone'
import _ from 'underscore'
import { applyDateMask } from 'util/controls'
import {
    activateLinks,
    attachThumbSrc,
    formatDate,
    formatNote,
    formatPhone,
    guessMimeType, initSingleSelectDropdown,
    isRenderableVideoType,
    parseDate,
    postRenderImages
} from "util/twistle"
import {
    raiseAlert,
    raiseConfirm
} from "util/alert_util"

import { localize } from 'util/localize'

import { betterFormatDate } from 'util/date'

import { isHandheld } from "util/breakpoints"

import { ajax } from "util/ajax"
import { escapeHTML, isBlank } from "util/string"
import { renderTemplate } from 'util/template_renderer'
import { validatePhoneNumber } from 'util/validators'
import Contact from 'app/models/contact'
import DialogView from 'app/views/dialog_view'

// Top-level view for capturing some base interactions (collapsable areas, etc etc)
App.BaseBehavior = Backbone.View.extend({
    el: "body",
    currentView: null,
    events: function () {
        var eventMap = {
            'click .collapseable_detail_section_title': 'expandOrCollapseDetailSection',
            'keyup': 'handleKeyboard',
            'click .tws_dial_number': "initiateVoiceCall"
        }
        if (App.isDesktop()) {
            _.extend(eventMap, {
                'mouseenter .tt': 'showToolTip',
                'click .tt': 'toolTipClickCheck',
                'mouseleave .tt,.tooltip_dialog': 'hideToolTip'
            })
        }
        return eventMap;
    },
    initialize: function() {
        _.bindAll.apply(_, [this].concat(_.functions(this)));
        App.bind("events:click_event_stopped", this.toolTipClickCheck, this);
        App.bind("app:view_changing", this.tooltipDelayClose, this);
        App.bind("app:show_mask", this.toolTipClickCheck, this);
        App.bind("app:close_conversation", this.toolTipClickCheck, this);
        App.bind("app:dial_number", this.initiateVoiceCall);
    },
    expandOrCollapseDetailSection: function(evt) {
        var section = $(evt.currentTarget).parent();

        if (section.hasClass("collapsed")) {
            section.removeClass("collapsed");
            section.trigger("sectionExpanded");
        } else {
            section.addClass("collapsed");
            section.trigger("sectionCollapsed");
        }
        section.trigger("sectionToggled");
    },

    showToolTip: function(evt){
        var self = this;
        var $targ = $(evt.currentTarget);
        var title = $targ.data("tooltip");
        var delay = $targ.data("tooltip-delay");
        var ttClass = $targ.data("tooltip-class") || "";
        var visibilityMediaQuery = $targ.data("tooltip-visibility");
        var pos = {};
        var offset = "";

        if (visibilityMediaQuery) {
            var query = window.matchMedia(visibilityMediaQuery);
            if (!query.matches) {
                return;
            }
        }

        if(App.isTouch() && $targ.hasClass("no_mobile_tt"))
        {
            // certain tooltips interfere w/ taps on mobile
            return;
        }

        //determin if a specific position reference class is indicated for this tooltip
        if($targ.hasClass("tt-below")){
            pos = {positionBelow:$targ, offsetY:"+20"};
        }
        else if($targ.hasClass("tt-below-right")){
            pos = {positionBelowLeftOf:$targ, offsetY:"+20"};
            ttClass += " below_right";
        }
        else if($targ.hasClass("tt-above")){
            pos = {positionAbove:$targ, offsetY:"-8"};
            offset = "0 -20";
        }
        else if($targ.hasClass("tt-above_right")){
            pos = {positionAbove:$targ, offsetY:"-20"};
            ttClass += " above_right";
        }
        else if($targ.hasClass("tt-above_left")){
            pos = {positionAbove:$targ,offsetY:"-20", offsetX:"+20"};
            ttClass += " above_left";
        }
        else if($targ.hasClass("tt-top_left")){
            pos = {positionLeftTop: $targ, offsetY:"-35"};
        }
        else {
            // otherwise just let the default dialog positioning do its thing
            pos = {positionNear:$targ, offsetX:"+10"};
        }
        if(title && title.length && $targ.is(":visible")){
            self.ttTimer = setTimeout(function(){
                    // close others
                    self.hideToolTip(evt, true);
                    setTimeout(function(){
                        var $el = $("<div class='tooltip_container'>"+title+"</div>").appendTo("body");

                        App.openDialogView(new DialogView(_.extend(
                            {   el:$el,
                                width:$targ.data("tooltip-width") || "auto",
                                height:$targ.data("tooltip-height") || $el.height() + 14,
                                pushIfMobile:false,
                                modal: !!App.isTouch(),
                                keepOverlayClass:true,
                                dialogClass:"dialog_panel tooltip_dialog " + ttClass
                            }, pos)));
                    },50);
                },(delay||350));
        }
    },

    hideToolTip: function(evt, clear){
        var self = this;
        if(self.ttTimer && !clear){
            clearInterval(self.ttTimer);
        }
        $(".tooltip_dialog .ui-dialog-content").each(function(){
            let bbView = $(this).data("backboneView");
            if(bbView){
                bbView.close();
            }
        });

        $(".tooltip_container").each(function() { $(this).remove() });
    },
    toolTipClickCheck: function(evt){
        var self = this;
        self.hideToolTip(evt);
    },

    tooltipDelayClose: function(evt)
    {
        var self = this;
        setTimeout(function(){
            self.hideToolTip(evt);
        },300);
    },

    handleKeyboard: function(evt) {
        var key = evt.keyCode || evt.which;
        if (key === 27) { // ESC
            App.logClickstream('escKeyHit');
            App.trigger('app:escape_key_pressed');
        }
    },

    initiateVoiceCall: function(evt, phoneToCall, accountToCallAttribs, orgId, $btn){
        var $targ = $(evt.currentTarget), self = this;

        if(!App.account.clickToCallOrgId() || self.voiceCallInProgress)
        {
            return true;
        }

        if(!validatePhoneNumber(App.account.get("phone")), App.config.get("country_codes"))
        {
            raiseAlert("Your Twistle account does not have a valid phone number. " +
                "Please update your profile!");
            return false;
        }

        if(!orgId)
        {
            orgId = App.account.clickToCallOrgId();
        }

        if(!phoneToCall)
        {
            phoneToCall = $targ.data("phone");
        }

        phoneToCall = phoneToCall.toString(); // just in case it somehow got cast to an int

        if(orgId && phoneToCall)
        {
            self.voiceCallInProgress = true;
            ajax.request("/voice/InitiateCall", {
                organization_id: orgId,
                phone_to_call: phoneToCall,
                account_to_call_id: accountToCallAttribs && accountToCallAttribs.id
            }, [], function (resp)
            {
                raiseConfirm("We are dialing your number: " + App.account.get("phone") + ". " +
                    "\nWe will then connect you to " + formatPhone(phoneToCall) + ".",
                    undefined, // OK is fine for text
                    undefined, // no OK callback
                    function(evt, confirmDialog) // cancel callback
                    {
                        ajax.request("/voice/CancelCall", {seqnum:resp.seqnum},[],function(){
                            confirmDialog.hide();
                        }, undefined, undefined, $(evt.currentTarget));
                    }
                );
                self.voiceCallInProgress = false;
            }, undefined, function(){
                self.voiceCallInProgress = true;
                return false;
            }, $btn);
            return false;
        }

        return true;

    }

});

// mixin for animating panels left / right
// requires that self.$panelWrapContainer is defined

App.HandheldSideBySidePanelAnimator = {
    handleInactiveHandheldPanelClick: function(evt)
    {
        if(isHandheld())
        {
            var self = this,
                $right = self.$(".right_panel");

            if(!self.panelIsDragging) // click event is fired after drag releases
            {
                if(!$right.hasClass("active"))
                {
                    self.activateRightPanel();
                }
                else
                {
                    self.deactivateRightPanel();
                }
            }
            self.panelIsDragging = false;
        }
    },

    handleInactiveHandheldPanelSwipe: function(evt)
    {
        if(isHandheld())
        {
            var self = this,
                $right = self.$(".right_panel");

            if(!self.panelIsDragging) // click event is fired after drag releases
            {
                var winWidth=$(window).width(),
                    distanceFromRight = winWidth - (evt.pageX || 0),
                    distanceFromLeft = (evt.pageX || 0);

                if(!$right.hasClass("active") && evt.direction && evt.direction === "left" && distanceFromRight < 110)
                {
                    self.activateRightPanel();
                }
                else if($right.hasClass("active") && evt.direction && evt.direction === "right" && distanceFromLeft < 95)
                {
                    self.deactivateRightPanel();
                }
                self.panelIsDragging = false;
            }

        }
    },

    activateRightPanel:_.throttle(function()
    {
        var self = this,
            winWidth=$(window).width(),
            viewWidth=Math.ceil(winWidth * 0.9),
            transWidth=Math.ceil(winWidth * 0.8),
            $left = self.$(".left_panel"),
            $leftMask = $left.find(".handheld_mask"),
            $right = self.$(".right_panel"),
            $rightMask = $right.find(".handheld_mask");

            $right.css({width:viewWidth});

            var transProps = {x:-transWidth};
            if(!$.support.transition)
            {
                transProps = {left:-transWidth, width:winWidth};
            }


            self.$panelWrapContainer.transition(transProps, 350, function()
            {

                $rightMask.transition({opacity:0.0},400,function(){
                    $right.addClass("active").removeClass("inactive");
                });

                $left.addClass("inactive").removeClass("active");
                $leftMask.transition({opacity:0.4});

            });

     },850,{trailing:false}),


    deactivateRightPanel:_.throttle(function()
    {
        var self = this,
            $left = self.$(".left_panel"),
            $leftMask = $left.find(".handheld_mask"),
            $right = self.$(".right_panel"),
            $rightMask = $right.find(".handheld_mask");

        var transProps = {x:0};
        if(!$.support.transition)
        {
            transProps = {left:0, width:"auto"};
        }

        self.$panelWrapContainer.transition(transProps, 350, function()
        {
            $leftMask.transition({opacity:0.0},400,function(){
                $left.addClass("active").removeClass("inactive");
            });
            $right.addClass("inactive").removeClass("active");
            $rightMask.transition({opacity:0.75});
        });

    },850,{trailing:false}),

    handleInactiveHandheldPanelDrag:function(evt)
    {
        var self = this,
            $right = self.$(".right_panel"),
            winWidth=$(window).width(),
            transWidth=Math.ceil(winWidth * 0.8),
            gesture = evt.gesture;
        //console.log("TWS:GEST : DRAG : " + JSON.stringify({x:gesture.deltaX}));
        if(gesture)
        {
            var deltaX = Math.abs(gesture.deltaX) || 0;
            var deltaY = Math.abs(gesture.deltaY) || 0;
            if(deltaX > 15 && deltaX < transWidth && deltaY < 20 && !self.panelVerticalScrollDetected)
            {
                self.panelIsDragging = true;
                var startX = $right.hasClass("active") ? -transWidth : 0,
                    adjustedX = Math.min(0,Math.max(startX + gesture.deltaX, -transWidth));
                self.$panelWrapContainer.css({overflow:"visible"}).stop(true,false).animate({x:adjustedX},0);
                evt.preventDefault();
            }
            else if(deltaY >= 20)
            {
                self.panelVerticalScrollDetected = true;
            }
        }
    },

    handleInactiveHandheldPanelRelease: function(evt, data)
    {
        var self = this,
            currentX = parseFloat(self.$panelWrapContainer.css("x")),
            $right = self.$(".right_panel"),
            winWidth=$(window).width(),
            transWidth=Math.ceil(winWidth * 0.8),
            threshold = transWidth * ($right.hasClass("active") ? 0.92 : 0.12) ;
        if(self.panelIsDragging)
        {
            // console.log("TWS:GEST : DRAG RELEASE");
            self.panelIsDragging = false;
            if(Math.abs(currentX) > threshold)
            {
                self.activateRightPanel();
            }
            else
            {
                self.deactivateRightPanel();
            }
            evt.preventDefault();
        }
        self.panelVerticalScrollDetected = false;

    },

    handleBreakpointExit:function(bp)
    {
        var self = this;
        if(bp === "handheld" && self.$panelWrapContainer)
        {
            self.$panelWrapContainer.css({x:0});
            self.$(".left_panel,.right_panel").removeClass("active").removeClass("inactive").css({width:"auto"});
            self.panelWrapContainerHasLeftHandheld = true;
        }
    },

    handleBreakpointEnter:function(bp)
    {
        var self = this;
        // re-entering handheld need to remove applied width style
        if(bp === "handheld" && self.panelWrapContainerHasLeftHandheld)
        {
            self.$(".right_panel").css("width", "");
        }
    }
};

App.WorkflowExecutionDisplayMixin = {
    viewExecutionTargetUser: function(evt){
        const self = this, $targ = $(evt.currentTarget);
        $targ.spin({top:2,left:$targ.width()-18,length:2.5,width:1.5,radius:3});
        if(self.contactDetailHoverPopup){
            self.contactDetailHoverPopup.close();
        }
        let contact = new Contact({username: $targ.data("username")});
        contact.fetch({fetchFromServer:true, success:function(){
          self.contactDetailHoverPopup = App.openDialogView(new DialogView({
              modal: true,
              overlayClass: "dark_overlay",
              width: 570,
              height: Math.min(640, $(window).height() - 250),
              title: "Contact Details"
          }));
          let contactView = self.contactDetailHoverPopup.addChildView(new App.ContactDetailView({
              standalone: false,
              model: contact,
              closeOnNavigate: true
          }));
          self.contactDetailHoverPopup.getEl().append(contactView.$el);
          contactView.render();
          self.contactDetailHoverPopup.reposition();
          $targ.spin({stop:true});
        }});
    },
    onViewStackChange: function(fromView, toView){
        let self = this;
        if(self.contactDetailHoverPopup){
            self.contactDetailHoverPopup.close();
        }
    }
};

App.FormDisplayMixin = {

    _baseAugmentFields : function(formDef)
    {
        let self = this,
            imageAttachContent = formDef.extended_props?.image_attach_content || {},
            renderId = new Date().getTime();

        _.each(formDef.fields, function(field) {
            // some mustache help
            field.field_id = field.id;
            // a more unique id that allows us to use <label for="dom id"/> within duplicating DOM ids
            field.field_dom_id = `${field.id}-${renderId}`;

            _.each(["label_image","instructions_image"], function(key)
            {
                if (field[key] && field[key].seqnum)
                {
                    // if we have a field label or instructions image in the base64 content cache that comes
                    // down with the form definition, construct a data url for the image (e.g. no server request
                    // necessary). The mime type is based on the file description (name) because we only store
                    // "image" as the `type` for image attachments
                    if(imageAttachContent[field[key].seqnum]){
                        field[key].url = `data:${guessMimeType(
                            field[key].description
                        )};base64,${imageAttachContent[field[key].seqnum]}`;
                    }
                    else{
                        field[key].url = attachThumbSrc(field[key], 'prf');
                        field[key].image_seqnum = field[key].seqnum;
                    }
                }
            });

            _.each(field.options, function(opt) {
                opt.option_dom_id = `${opt.id}-${renderId}`;

                if (opt.image && opt.image.seqnum) {

                    opt.image.option_id = opt.id; // mustache hoisting

                    // same base64 data url as label / instruction images above but for option images
                    if(imageAttachContent[opt.image.seqnum]){
                        opt.image.url = `data:${guessMimeType(
                            opt.image.description
                        )};base64,${imageAttachContent[opt.image.seqnum]}`;
                    }
                    else {
                        opt.image.url = attachThumbSrc(opt.image, 'prf');
                        opt.image.image_seqnum = opt.image.seqnum;
                    }
                }
            });

            field.options_max_index = (field.options || []).length - 1;

            if(field.field_type === "attachments") {
                field.upload_label = "Upload / Choose Files";
                if(field.allowed_types === "image/*")
                {
                    field.upload_label = "Take / Choose Picture";
                }
                else if(field.allowed_types === "twistle/rdx-video-upload")
                {
                    field.upload_label = "Record video";
                }
                else if(field.allowed_types === "twistle/digitalsignature")
                {
                    field.field_type = "digsig";
                }
            }

            // if we are selecting values for matching, switch any option having field to checkboxes (e.g. this value OR that value)
            if(self.options.asOptionMatchesFor
                && !_.findWhere(field.options || [], {id:self.options.asOptionMatchesFor})
                && _.contains(["slider","select","autocomplete","radio"], field.field_type))
            {
                field.field_type = "checkboxes";
            }

            field["field_type_" + field.field_type] = true;

            if (field.extended_props?.length > 0)
            {
                field.extended_props = JSON.parse(field.extended_props);
            }

            if(!_.isObject(field.preconditions))
            {
                field.preconditions = JSON.parse(field.preconditions || "{}");
            }

        });
    },

    _addField : function($container, field)
    {
        var self = this, fieldDataToRender;

        if(self.options.currentUILanguage) {
            // deeply clone field data
            fieldDataToRender = JSON.parse(JSON.stringify(field));
            // switch label / instructions
            fieldDataToRender.label = self.getLanguageVal(field, "label");
            fieldDataToRender.instructions = self.getLanguageVal(field, "instructions");
            _.each(fieldDataToRender.options,function(opt){
                opt.label =  self.getLanguageVal(opt, "label");
            });
        } else {
            fieldDataToRender = field;
        }

        if(field.field_type === "text")
        {
            field.text_input_type = "text";
        }

        var $field = $(renderTemplate('form_submission_field_template', fieldDataToRender)).appendTo($container);

        if (field.datefield)
        {
            var $dateField = $field.find("input[name=field_" + field.id + "]");
            if(field.extended_props?.date_text_input){
                const dobElt = $dateField[0];
                if (dobElt) {
                    applyDateMask(dobElt);
                }
                $dateField.attr({autocomplete: "off", autocorrect: "off", autocapitalize:"off", spellcheck:false});
            }
            else{
                $dateField.tws_datePicker();

                if(field.datetime_default_now && !(self.formDef?.submission && self.options.readonly) )
                {
                    // set the default date to the current date unless we are looking at a submission in read only mode
                    $dateField.tws_datePicker("setDate", new Date());
                }
            }
        }
        else if (field.timefield)
        {
            var $timeField = $field.find("input[name=field_" + field.id + "]")
                $timeField.timepicker({timeFormat: 'g:i a',
                                       noneOption: {label:"",value:""},
                                       useSelect: false,
                                       listWidth: 1,
                                       appendTo: self.getEl(),
                                       className: "tws-form-timepicker",
                                       disableTouchKeyboard: false});
                $timeField.on("showTimepicker", function(el){
                    let list = this.timepickerObj?.list;
                    // adjust the width of the drop down list to account for css box-model - this widget has annoying
                    // buglet when both the input and drop-down use box-sizing: border-box
                    if(list){
                        list.width(list.width()-2);
                    }
                });

            if(field.datetime_default_now && !(self.formDef?.submission && self.options.readonly))
            {
                // set the default time to the current time unless we are looking at a submission in read only mode
                $timeField.timepicker("setTime", new Date());
            }
        }

        if (field.field_type === "select") {
            $field.find(".twistle_dropdown").twistle_dropdown({
                addHiddenField: true,
                tappableTargets: true,
                itemselected: function (e, $item) {
                    var $dd = $(this), optionId = parseInt($dd.twistle_dropdown("getValue").value, 10);
                    self.validateForm(optionId, field.id, true);
                },
                maxHeight: 340
            });
        }
        else if (field.field_type === "slider")
        {
            if($("html").hasClass("lte9"))
            {
                $field.find(".field_slider.field_" + field.id).data("field", field).slider(
                    {
                        min: 0,
                        max: (field.options.length - 1),
                        step: 1,
                        create: function (event, ui)
                        {
                            var $this = $(this);
                            $this.parent().find(".field_slider_label_field_" + field.id).text($this.data("field").options[ui.value || 0].label);
                            $this.parent().find("input[name=field_" + field.id + "]").val($this.data("field").options[ui.value || 0].id);
                        },
                        change: function (event, ui)
                        {
                            var $this = $(this), field = $this.data("field");
                            $this.parent().find(".field_slider_label_field_" + field.id).text(field.options[ui.value].label);
                            $this.parent().find("input[name=field_" + field.id + "]").val(field.options[ui.value].id);
                            self.validateForm(field.options[ui.value].id, field.id, true);
                        }
                    }
                );

            }
            else
            {
                var $input = $field.find("input[type='range'].field_" + field.id + "_range").data("field", field);
                $input.on("change", function(evt){
                    var $this = $(this), idxVal = parseInt($this.val(), 10);
                    $this.parent().find(".field_slider_label_field_" + field.id).text($this.data("field").options[idxVal || 0].label);
                    $this.parent().find("input[name=field_" + field.id + "]").val(field.options[idxVal].id);
                });
                if(!self.options.readOnly){
                    // when in an actual submission mode, we emit a change event such that the initial setting of the
                    // slider is picked
                    $input.trigger("change");
                }
            }

        }
        else if(field.field_type === "autocomplete")
        {
            // massage data for AC
            var acOpts = _.map(field.options||[],function(opt){
               return {
                   id:opt.id,
                   value:opt.label,
                   label:opt.label
               };
            });
            $field.find("input.tws_form_input_autocomplete").autocomplete({
                                delay:70,
                                minLength: 0,
                                minChars: 0,
                                position: { collision: "flip"},
                                appendTo: self.getEl ? self.getEl() : self.$el,
                                source: function( request, response ) {
                                    const matcher = new RegExp( $.ui.autocomplete.escapeRegex(request.term), "i" );
                                    const resp = [];
                                    for(let i = 0; i< acOpts.length; i++){
                                        if(!request.term || matcher.test(acOpts[i].label)){
                                            resp.push(acOpts[i])
                                        }
                                        if(resp.length > 50){
                                            // cap the max number of results in the response
                                            break;
                                        }
                                    }
                                    response(resp);
                                },
                                select: function( event, ui ) {
                                    $field.find("input.tws_form_input_autocomplete_value").val(ui.item.id);
                                    self.validateForm(ui.item.id, field.id, true);
                                },
                                change: function( event, ui ) {
                                    $field.find("input.tws_form_input_autocomplete_value").val(ui.item.id);
                                    self.validateForm(ui.item.id, field.id, true);
                                }
            });
        }
        else if (field.field_type === "attachments")
        {
            let allowedTypes = field.allowed_types;
            if(allowedTypes === "twistle/rdx-video-upload"){
                allowedTypes = "video/*";
            } else if (allowedTypes === "application/vnd.ms-word") {
                allowedTypes = "application/msword,application/vnd.ms-word,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            }
            $field.find(".upload_button").twistle_attachments({  useWrap:true,
                                                fileListUi:$field.find(".field_attachments_container"),
                                                fileElementClass:"fileElementInResponse",
                                                allowedTypes: allowedTypes,
                                                customFileUi: true,
                                                uploadBegin:function(evt){

                                                },
                                                upload_type_failure: function() {
                                                    let warningContent;
                                                    switch(allowedTypes){
                                                        case "image/*":
                                                            warningContent = localize(
                                                                "CustomForm-wrongUploadedFileTypeAlertImage",
                                                                "This field only accepts uploads of images.");
                                                            break;
                                                        case "video/*":
                                                            warningContent = localize(
                                                                "CustomForm-wrongUploadedFileTypeAlertVideo",
                                                                "This field only accepts uploads of videos.");
                                                            break;
                                                        case "application/pdf":
                                                            warningContent = localize(
                                                                "CustomForm-wrongUploadedFileTypeAlertWord",
                                                                "This field only accepts uploads of Adobe PDF documents.");
                                                            break;
                                                        case "application/vnd.ms-word":
                                                            warningContent = localize(
                                                                "CustomForm-wrongUploadedFileTypeAlertPDF",
                                                                "This field only accepts uploads of Microsoft Word documents.");
                                                            break;
                                                        default:
                                                            warningContent = `Your uploaded file is not of the correct type - it must be ${allowedTypes}`;
                                                    }
                                                    raiseAlert(warningContent);
                                                },
                                                attach_removed:function(evt){
                                                    const $this = $(this);
                                                    $this.parent().find("input[name=field_" + field.id + "]").val($this.twistle_attachments("attachments").join(","));
                                                    // causes UI to refresh appropriately
                                                },
                                                upload_success:function(evt){
                                                    var $this = $(this);
                                                    $this.parent().find("input[name=field_" + field.id + "]").val($this.twistle_attachments("attachments").join(","));
                                                    }
                                                });
        }

        var hasDefault = (field.default_value && field.default_value.length) || _.findWhere(field.options||[],{default_selected:true});
        let submittedByAccount;


        if (self.formDef?.submission ||
            self.formDef?.saved_draft_values ||
            self.options.readOnly ||
            hasDefault ||
            self.options.asOptionMatchesFor ||
            self.options.fieldPreselections)
        {
            var submittedValue = {value: "", field_option_ids: []};

            if(self.options.fieldPreselections && self.options.fieldPreselections[field.id])
            {
                submittedValue = self.options.fieldPreselections[field.id];
            }

            if(self.formDef?.submission && self.formDef.submission.values[field.id])
            {
                submittedValue = self.formDef.submission.values[field.id];
                submittedByAccount = self.formDef.submission.account;
            }
            else if(self.formDef?.saved_draft_values && self.formDef.saved_draft_values[field.id])
            {
                submittedValue = self.formDef.saved_draft_values[field.id];
                submittedByAccount = App.account.toJSON();
            }
            else if(hasDefault)
            {
                submittedValue = {value:field.default_value || "",
                                  field_option_ids: _.pluck(_.where(field.options||[],{default_selected:true}),
                                                            'id')};
            }

            // if we are showing this form to select option matches (for workflow matching or preselecting)
            if(self.options.asOptionMatchesFor &&
                _.has(self.options.currentOptionMatches || {}, field.id))
            {
                submittedValue.field_option_ids = self.options.currentOptionMatches[field.id];
            }

            if (field.options)
            {
                self._setSelectedOptionsOrValue(field, submittedValue.field_option_ids, $container, self.options.readOnly)
            }
            else if(field.field_type === "attachments") {
                // make sure the hidden field which contains the actual value (comma sep list of attachment guids)
                // is populated
                self._setSelectedOptionsOrValue(field, submittedValue.value, $container, self.options.readOnly);

                if(submittedValue.attachments?.length > 0){
                    $field.find(".field_attachments_container").show();
                    $field.find(".upload_button").twistle_attachments(
                        "setAttachments",
                        submittedValue.attachments,
                        self.options.imagesFullSize || App.config.get("isStaticOutput")
                    );
                }
            }
            else if(field.field_type === "digsig") {
                if(submittedValue.attachments && submittedValue.attachments.length > 0) {
                    self.addSignatureComplete(field.id, submittedValue.attachments[0], submittedByAccount?.formalname);
                }

            }
            else
            {
                self._setSelectedOptionsOrValue(field, submittedValue.value, $container, self.options.readOnly);

                if(submittedValue.has_recording)
                {
                    $container.find(".play_recording").data("field_id",field.id).show();
                }
            }

            if(self.options.readOnly)
            {
                // disable inputs
                $field.find("input,select,textarea").prop("readonly", true).prop("disabled", true);

                // upload buttons
                $field.find(".sign_form_button,.upload_button").hide();

                // replace text values
                $field.find("input.tws_form_text_input,input.tws_form_input_autocomplete,textarea.tws_form_text_input_textarea").each(function(idx, el){
                    var $el = $(el);
                        if(!$el.hasClass("tws_form_text_hidden"))
                        {
                            $el.addClass("tws_form_text_hidden").hide();
                            var $newEl = $("<span class='tws_form_text_readonly'>" + $el.val() + "</span>");
                            $newEl.addClass("field_type_" + field.field_type);
                            $newEl.insertBefore($el);
                        }
                });
            }
        }

        activateLinks($field, ".field_instructions:not('.has_image')", undefined, undefined, field.id, undefined, undefined, App.config.get("country_codes"));
        postRenderImages($field);
    },

    _setSelectedOptionsOrValue: function(field, selected_option_ids_or_value, $container, readOnly){
        var self = this;

        if(!$container)
        {
            $container = self.$fieldWrap;
        }
        if(field.options)
        {
            switch (field.field_type)
            {
                case "select":
                    if (selected_option_ids_or_value.length)
                    {
                        $container.find(".twistle_dropdown.field_" + field.id).twistle_dropdown(
                            "selectItem", parseInt(selected_option_ids_or_value[0], 10)
                        );
                        self._renderChildFields(field, selected_option_ids_or_value[0]);
                    }
                    else
                    {
                        $container.find(".twistle_dropdown.field_" + field.id).twistle_dropdown(
                            "selectItem", undefined
                        );
                    }
                    if (readOnly)
                    {
                        $container.find(".twistle_dropdown.field_" + field.id).twistle_dropdown("setEnabled", false);
                    }
                    break;
                case "slider":
                    if (selected_option_ids_or_value.length)
                    {
                        let idx = _.indexOf(_.pluck(field.options, "id"), parseInt(selected_option_ids_or_value[0],10));

                        if($("html").hasClass("lte9"))
                        {
                            $container.find(".field_slider.field_" + field.id).slider("value", idx);
                        }
                        else
                        {
                            var $range = $container.find("input[type='range'].field_" + field.id + "_range");
                            $range.val(idx);
                            $range.trigger("change");
                        }
                        self._renderChildFields(field, selected_option_ids_or_value[0]);
                    }
                    else
                    {
                        if($("html").hasClass("lte9"))
                        {
                            $container.find(".field_slider.field_" + field.id).slider("value", 0);
                        }
                    }
                    if (readOnly)
                    {
                        if($("html").hasClass("lte9"))
                        {
                            $container.find(".field_slider.field_" + field.id).slider("disable", true);
                        }
                    }
                    break;
                case "radio":
                case "checkboxes":
                    $container.find(".field_" + field.id + " input").each(
                        function (idx, formEl)
                        {
                            var $formEl = $(formEl);
                            var isSelected = _.contains(selected_option_ids_or_value, $formEl.data("option_id"));
                            if (isSelected)
                            {
                                if(readOnly)
                                {
                                    $formEl.attr("checked", "checked");
                                }
                                else
                                {
                                    $formEl.prop("checked", true);
                                }

                                self._renderChildFields(field, $formEl.data("option_id"));

                                // stop now if we are a radio
                                if(field.field_type === "radio")
                                {
                                    return false;
                                }

                            }
                            else
                            {
                                $formEl.prop("checked", false);
                            }
                        }
                    );
                    break;
                case "autocomplete":
                    if (selected_option_ids_or_value.length)
                    {
                        let idx = _.indexOf(_.pluck(field.options, "id"), parseInt(selected_option_ids_or_value[0],10));
                        if (idx > -1)
                        {
                            $container.find(".field_" + field.id + " input.tws_form_input_autocomplete").val(field.options[idx].label);
                            $container.find(".field_" + field.id + " input.tws_form_input_autocomplete_value").val(field.options[idx].id);
                            self._renderChildFields(field, selected_option_ids_or_value[0]);
                        }
                    }
                    else
                    {
                        $container.find(".field_" + field.id + " input.tws_form_input_autocomplete").val("");
                        $container.find(".field_" + field.id + " input.tws_form_input_autocomplete_value").val("");
                    }
                    break;
            }
        }
        else
        {
            var $input = $container.find("input[name=field_" + field.id + "],textarea[name=field_" + field.id + "]");

            if (field.datefield && !field.extended_props?.date_text_input && selected_option_ids_or_value?.length > 0) {
                $input.tws_datePicker("setDate", parseDate(selected_option_ids_or_value));
            }
            else {
                $input.val(selected_option_ids_or_value);
            }

        }
    },

    _renderChildFields: function(field, selectedOptionId, selectedOptionLabel, conditionalFieldState)
    {
        const self = this,
            matchedFields = _.filter((self.formDef && self.formDef.fields) || [], function(field) {
                let isMatch = field.requires_field_option && parseInt(field.requires_field_option, 10) === parseInt(selectedOptionId, 10);
                if (!_.isEmpty(conditionalFieldState) && _.has(conditionalFieldState, field.id) && !conditionalFieldState[field.id]) {
                    isMatch = false;
                }
                return isMatch;
            });
        if(matchedFields.length > 0)
        {
            var $container = self.$(".form_field_wrap_"+field.id + " .child_fields_for_option_"+selectedOptionId);
            if($container.length === 0)
            {
                $container = $("<div></div>").addClass("child_fields child_fields_for_option_" + selectedOptionId).appendTo(self.$(".form_field_wrap_" + field.id));

                if(selectedOptionLabel && ((self.options.readOnly && self.options.asTemplate) || self.options.showBranchHints))
                {
                    $container.append('<div class="child_field_wrap_header">Shown when "'+ escapeHTML(selectedOptionLabel) +'" is selected</div>');
                }
                _.each(
                    matchedFields, function (field) {
                        self._addField($container, field);
                    }
                );
                $container.find(".tws_form_field").first().addClass("first_field");
                $container.find(".tws_form_field").last().addClass("last_field");
            }
            else
            {
                if(!$container.is(":visible"))
                {
                    $container.show();
                }
            }
        }

        return matchedFields.length > 0;

    },

    _baseAugmentForm: function (formDef) {
        let self = this;
        formDef.has_fields = formDef.fields && formDef.fields.length > 0;

        if(!_.isObject(formDef.extended_props)){
            formDef.extended_props = JSON.parse(formDef.extended_props || "{}");
        }

        if(!_.isObject(formDef.title_languages)){
            formDef.title_languages = JSON.parse(formDef.title_languages || "{}");
        }

        self._baseAugmentFields(formDef);

        _.each(formDef.fields, function (field) {
            field.form_def_controls = true;
        });

        formDef.orgHasClients = App.account.getOrgById(formDef.organization).has_clients;
        formDef.showId = App.account.get("user_admin");
        formDef.showAudit = App.account.canEditFormsAndWorkflows();
        formDef.showAddProms = App.account.get("user_admin");
        formDef.conditional_fields_enabled = formDef.extended_props?.conditional_fields_enabled;

        // see LanguageSelectionMixin
        self.augmentLanguageFields(formDef);

        // normalize versions
        formDef.versions = _.map(formDef.form_versions || [], function (version) {
            if (version.id === formDef.id) {
                version.is_current = true;
            }
            return version;
        });

        // normalize standard score stuff
        formDef.standardized_score_options = _.map(
            formDef.standardized_score_types,
            function (score_info, score_type) {
                score_info.score_type = score_type;
                score_info.enabled = _.has(formDef.standardized_score_config, score_type);
                if (score_info.enabled && self._populateStandardScoreStatus) {
                    // only relevant on FormDefinitionDialogView
                    self._populateStandardScoreStatus(formDef, score_info);
                }
                return score_info;
            });
        formDef.uses_standardized_score_function = _.keys(formDef.standardized_score_config || {}).length > 0;

        // if "calculate score" is on, and there is no standard scoring enabled *or* there is a custom scoring function
        // we set this flag to make our templates simple in terms of whether or not a total score / custom score will
        // be shown.
        formDef.has_custom_scoring = (formDef.custom_score_function && formDef.custom_score_function.length > 0);
        formDef.show_total_or_custom_score = formDef.calculate_total_score && (
            !formDef.uses_standardized_score_function || formDef.has_custom_scoring
        );

        // Order versions in descending order and show the live version first
        formDef.versions = _.chain(formDef.versions).sortBy('version_live').reverse().value();

        _.each(formDef.versions, function(version) {
            version["created_time"] = betterFormatDate(version.adddate, "mm/dd/yyyy");
            version["set_live_time"] = betterFormatDate(version.version_date, "mm/dd/yyyy");
            version["has_comments"] = !isBlank(version.version_comments || "");
        });

        let versionCount = _.size(formDef.versions);
        // When viewing the live version we only show the live version tile, so we should "show more" if > 1 version exists
        // When viewing a version that isn't live, we show the live tile and the version that is being viewed, so "show more" if > 2 versions exist
        formDef["showMore"] = formDef.version_live ? versionCount > 1 : versionCount > 2

        return formDef;
    },
    _baseAugmentSubmission: function (formDef, submission) {
        submission.displayDate = formatDate(submission.moddate);
        submission.account = new Contact(submission.account || {}).toJSON();

        if(submission.last_account && submission.last_account.id != submission.account?.id){
            submission.displayDate = formatDate(submission.adddate);
            submission.modifiedDisplayDate = formatDate(submission.moddate);
            submission.modifiedAccount = new Contact(submission.last_account || {}).toJSON();
        }

        // unpack standard scores for display
        submission.standardized_score_output = _.map(formDef.standardized_score_options || [],
            function (scoreConfig) {
                return {
                    enabled: scoreConfig.enabled,
                    label: scoreConfig.label,
                    score: (submission.standardized_scores &&
                        submission.standardized_scores[scoreConfig.score_type])
                }
            });

        // helpers for which scores we output...
        submission.show_total_or_custom_score = formDef.show_total_or_custom_score;
        if(submission.show_total_or_custom_score){
            submission.total_or_custom_score = (formDef.has_custom_scoring ?
                submission.score_custom : submission.score_total);
        }

        return submission;
    }

};

App.LanguageSelectionMixin = {
    augmentLanguageFields:function(obj){
        var availableLanguages = App.config.get("availableLanguages");
        var newData = {
            availableLanguages:_.values(availableLanguages)
        };

        newData.default_language_data = availableLanguages[obj.default_language || "en"];

        if(!_.isArray(obj.supported_languages)){
            obj.supported_languages = (obj.supported_languages && obj.supported_languages.split("|")) || [];
        }

        if(obj.supported_languages?.length > 0){
            obj.has_alternate_languages = true;
        }

        newData.supported_languages_data = _.map(obj.supported_languages, function(lang){
           return availableLanguages[lang] || {};
        });

        _.extend(obj, newData);

    },
    getSupportedLanguagesFromDialog : function()
    {
        var self = this;
        var supportedLang = [];

        self.$el.find(".language_setting").not(".default_language_setting,.language_preview").each(function (i, el)
        {
            supportedLang.push($(el).data("lang"));
        });

        var addVal = self.$(".add_language_dropdown").twistle_dropdown("getValue").value;

        if (addVal && addVal.length)
        {
            supportedLang.push(addVal);
        }

        return _.uniq(supportedLang).join("|");
    },

    addLanguage:function(evt)
    {
        var self = this,
            $btn = $(evt.currentTarget),
            $languageSelector = self.$(".add_language_dropdown");

        $btn.fadeOut();
        $languageSelector.show("slide");
    },

    removeLanguage:function(evt)
    {
        var self = this,
        $btn = $(evt.currentTarget);
        $btn.parent().remove();
        evt.stopPropagation();
    },

    setupLanguageSwitcher:function(langSwitchableObj, onDialog)
    {
        // on form field editor or workflow step editor,
        // configures the dialog w/ buttons in the title bar
        // for switching btw languages (if this form/workflow
        // supports other languages) otherwise just tracks
        // the object that has switchable languages

        var self = this;
        self.options.langSwitchableObj = langSwitchableObj; // used in further events
        var defaultLang = (self.options.langSwitchableObj.default_language || "en");
        var selectedLang = self.options.currentUILanguage || defaultLang;

        if(onDialog){
            self.$(".language_setting").each(function(idx, el){
                var $el = $(el);
                if($el.data("lang") === selectedLang)
                {
                    $el.addClass("selected");
                }
            });
        }
        else if(self.options.langSwitchableObj.supported_languages && self.options.langSwitchableObj.supported_languages.length > 0)
        {
            _.each([defaultLang].concat(self.options.langSwitchableObj.supported_languages), function(lang){
                var classNames = "language_setting_edit_nav_button language_setting language_setting_" + lang;
                if(lang === selectedLang){
                    classNames += " selected";
                }
                var $btn = self.setLanguageButton(classNames, lang, self.changeLanguageInTitleBar, "Change Language");
                $btn?.data("lang", lang);
            });
        }

        if(defaultLang !== selectedLang)
        {
            self.languageChanged();
        }
    },

    setLanguageButton: function(className, text, handlerFn, tooltip) {
        // add the language picker to this step dialog
        const self = this,
            $btn = $('<button class="' + className + '">' + (text || "") + '</button>');
        $btn.on('click', handlerFn);
        if (tooltip) {
            $btn.data("tooltip",tooltip).addClass("tt").addClass("tt-below");
        }

        self.$(".language_setting_row").show().append($btn);
        return $btn;
    },

    changeLanguageInTitleBar: function(evt)
    {
        var self = this, $targ = $(evt.currentTarget), newLang;

        if($targ.data("lang") !== (self.options.langSwitchableObj.default_language || "en"))
        {
            newLang = $targ.data("lang");
        }

        self.options.currentUILanguage = newLang;
        _.each(self.$titleButtons,function($btn){
            $btn.removeClass("selected");
        });
        $targ.addClass("selected");

        self.languageChanged();
        self.validateForm();
    },

    changeLanguageOnDialog:function(evt)
    {
        var self = this, $targ = $(evt.currentTarget), newLang;

        if($targ.data("lang") !== (self.options.langSwitchableObj.default_language || "en"))
        {
            newLang = $targ.data("lang");
        }

        self.$(".language_setting.selected").removeClass("selected");
        $targ.addClass("selected");
        self.options.currentUILanguage = newLang;

        self.languageChanged();

        self.validateForm();

    },


    getLanguageVal: function(obj, field, lang, subkey)
    {
        // extracts language specific string
        var self = this, langStrings = {};

        if(lang || self.options.currentUILanguage)
        {
            if (obj[field + "_languages"] && obj[field + "_languages"].toString().length > 0)
            {
                langStrings = obj[field + "_languages"];

                // potentially needs to be parsed
                if(!_.isObject(langStrings))
                {
                    langStrings = JSON.parse(langStrings);
                }

                if(subkey) // potentially need to follow a key inside this object
                {
                    langStrings = langStrings[subkey] || {};
                }

            }
            return langStrings[lang || self.options.currentUILanguage]
        }

        return subkey ? obj[field][subkey] : obj[field];

    },

    setLanguageFieldVal: function(obj, fieldKey, fieldVal, keepAsObj, subkey)
    {
        // assigns alternate language strings into an object (presumably being edited in the UI)
        var self = this;
        if (!self.options.currentUILanguage)
        {
            if(subkey)
            {
                obj[fieldKey][subkey] = fieldVal;
            }
            else
            {
                obj[fieldKey] = fieldVal;
            }
        }
        else
        {

            var langStrings = {};

            if(obj[fieldKey + "_languages"] && obj[fieldKey + "_languages"].toString().length > 0)
            {
                langStrings = obj[fieldKey + "_languages"];
                if(!_.isObject(langStrings))
                {
                    langStrings = JSON.parse(langStrings);
                }

                if(subkey)
                {
                    langStrings = langStrings[subkey] || {};
                }

            }

            langStrings[self.options.currentUILanguage] = fieldVal;

            if(subkey)
            {
                obj[fieldKey + "_languages"][subkey] = keepAsObj ? langStrings : JSON.stringify(langStrings);
            }
            else
            {
                obj[fieldKey + "_languages"] = keepAsObj ? langStrings : JSON.stringify(langStrings);
            }

        }
    }
};

App.FolderNavigatorMixin = {

    fetchFolders: function(animateProgress, onComplete)
    {
        var self = this,
            $breadcrumbContainer = self.$(".breadcrumb-container"),
            $container = self.$(".library_folder_list"),
            $filter = self.$(".attachment_library_criteria"),
            params = {
                organization_id: self.options.orgId
            };

        if(self.options.currentFolderId)
        {
            params.parent_folder_id = self.options.currentFolderId;
        }

        if(animateProgress)
        {
            $container.spin();
            $breadcrumbContainer.spin();
        }

        if($filter && $filter.length === 1)
        {
            params.title_contains = $filter.val();
        }


        ajax.request("/attachment/ListFolders",params,[],function(jsonResp){
            $breadcrumbContainer.empty();
            $container.empty();

            var parents = jsonResp.parents || [];

            // add our "home" folder
            parents.push({
                "id":undefined,
                "title":App.account.getOrgById(self.options.orgId).name || "Home"
            });

            var $breadcrumbs = $("<div></div>").addClass("library_folder_breadcrumbs");

            _.each(jsonResp.parents, function (folder, idx)
            {
                var $crumb = $('<a></a>');
                $crumb.data(folder)
                      .addClass("library_folder_breadcrumb")
                      .text(folder.title)
                      .prependTo($breadcrumbs);

                if (idx === 0) {
                    $crumb.addClass("selected");
                    if (parents.length > 1 && !self.options.chooseMode) {
                        // add edit button for this folder
                        $('<button class="edit_folder_button tws-link-button">Edit</button>')
                        .data(folder)
                        .appendTo($breadcrumbs);
                    }
                }

                if (idx === jsonResp.parents.length - 1) {
                    let $homeIcon = $("<img class='home_icon' alt='Home'></img>");
                    $homeIcon.data(folder).addClass("library_folder_breadcrumb");
                    $homeIcon.insertBefore($crumb);
                }

                if (idx < jsonResp.parents.length - 1) {
                    $breadcrumbs.prepend("<img class='arrow_left_icon' alt='divider'></img>");
                }
            });
            $breadcrumbs.appendTo($breadcrumbContainer);

            _.each(jsonResp.folders,function(folder){
                if (self.options.chooseMode && _.contains(self.options.selectedFolderIds || [], folder.id.toString())) {
                    // don't show the current folder as a destination
                    return;
                }
                var $folder = $(renderTemplate('attachment_library_folder_template',folder))
                                .data(folder);
                $folder.appendTo($container);
            });

            if(animateProgress)
            {
                $container.spin({stop: true});
                $breadcrumbContainer.spin({stop: true});
            }

            if(onComplete)
            {
                onComplete(jsonResp.folders && jsonResp.folders.length > 0);
            }
        });
    },

    openFolder: function(evt){
        const self = this;
        let $currentTarg = $(evt.currentTarget);
        let $targ = $(evt.target);

        if($currentTarg.hasClass("library_folder_breadcrumb") &&
                $currentTarg.data("id") &&
                self.options.currentFolderId === $currentTarg.data("id") &&
                !self.options.chooseMode)
            {
                // you clicked on current breadcrumb - redirect to edit folder props
                self.editFolder(evt);
                return;
            }

        if ($targ.hasClass("open-folder") && $targ.data("folder") != null) {
            self.options.currentFolderId = $targ.data("folder");
        } else {
            self.options.currentFolderId = $currentTarg.data("id");
        }

        self.clearSearchBar();

        self.fetchFolders();

        if(self.onOpenFolder)
        {
            self.onOpenFolder(evt);
        }
    },

    clearSearchBar: function() {
        const self = this;
        self.$(".search_input").val('');
    }
};

App.WorkflowChooserMixin = {
    expandChildWorkflowDefinitions: function(definitions)
    {
        /*
         * For the first pass at Yivo, to minimize changes to the frontend code, we expand out enterprise definitions
         * to one definition for the enterprise, plus one definition per child we're a member of. That means the only
         * code that has to care about enterprise defs (versus child defs) is this bit.
         */
        let self = this
        let orgs = App.account.get("orgs")
        let enterprises = orgs.filter(org => org.supports_child_organizations)
        let enterprise_child_map = {}

        enterprises.forEach(ent =>
            enterprise_child_map[ent.id] = orgs.filter(org => org.parent_organization?.id == ent.id).map(org => org.id)
        )

        /* Given enterprise 'definition', fan out to the original definition plus one per child org ID */
        let expandEnterpriseDefinition = function(definition, child_ids) {
            definition.definedByEnterprise = true;
            let child_definitions = child_ids.map(org_id => $.extend(true, {}, definition, {organization: org_id}))
            return _.flatten([definition, child_definitions])
        }

        let all_definitions = _.flatten(
                definitions.map(def => def.organization in enterprise_child_map
                ? expandEnterpriseDefinition(def, enterprise_child_map[def.organization])
                : def)
            );

        /* If we're targeting an actor, we're also implicitly targeting their current org, so filter down */
        return self.model
            ? all_definitions.filter(def => def.organization === App.account.getDefaultOrg().id)
            : all_definitions
    },

    updateWorkflowChooser:_.debounce(function(evt)
    {
        var self = this;
        var $container = self.$(".workflow_definition_lookup_wrap"),
            $currentList = $container.find(".workflow_definition_option");

        var code = evt && (evt.keyCode || evt.which);
        if(_.indexOf([
                13, // enter key
                38, // up key
                40 // down key
            ], code) > -1) {
            switch(code){
                case 13:
                    if($currentList.length === 1)
                    {
                        $($currentList.get(0)).click();
                    }
                    else
                    {
                        $currentList.filter(".keyhover").click();
                    }
                    break;
                case 38:
                case 40:
                    var currentSelected = $currentList.filter(".keyhover");
                    if(currentSelected.length === 1)
                    {
                        currentSelected.removeClass("keyhover");
                        var currentIndex = $currentList.index(currentSelected);
                        var newIndex;
                        if(code === 38){
                            newIndex = (currentIndex !== 0)?currentIndex-1:$currentList.length-1;
                        }
                        else{
                            newIndex = (currentIndex !== $currentList.length)?currentIndex+1:0;
                        }
                        $($currentList[newIndex]).addClass("keyhover");
                    }
                    else
                    {
                        $($currentList[0]).addClass("keyhover");
                    }
                    break;
            }

        }
        else
        {
            $currentList.remove();
            $container.spin();
            ajax.request("/workflows/ListDefinitions",
                {
                    query: self.$("input[name=workflow_definition]").val(),
                    target_user_id: self.model && self.model.get("id"),
                    visible_only: self.options.showVisibleWorkflowOnly,
                    live_only: true,
                    include_dependencies: false,
                    organization_id: self.options.showOrgId,
                    include_folder_titles: self.options.showFoldersInWorkflowSwitcher,
                    per_page: self.options.showFoldersInWorkflowSwitcher ? 15 : 5
                }, [], function (result)
                {
                    var hasResults = result.workflow_definitions && result.workflow_definitions.length > 0;
                    $container.find(".workflow_definition_option").remove();
                    $container.find(".workflow_definition_option_no_results").toggle(!hasResults);
                    if (hasResults)
                    {
                        let definitions = self.expandChildWorkflowDefinitions(result.workflow_definitions || []);

                        _.each(definitions, function (workflow)
                        {
                            _.extend(workflow, new App.WorkflowDefinition(workflow).toJSON());
                            workflow.showFolder = self.options.showFoldersInWorkflowSwitcher;
                            $(renderTemplate('workflow_definition_chooser_list_item_template', workflow)).appendTo($container);
                        });
                    }
                    $container.spin({stop: true});
                    self.reposition();
                }
            );
        }

        if(evt)
        {
            return false;
        }

    }, 150, true)

};

App.DayTimeRestrictionsInputMixin = {
    _baseDaysOfWeek: [{label:"Mon", day:"mon"}, {label:"Tue", day:"tue"}, {label:"Wed", day:"wed"},
                      {label:"Thu", day:"thu"}, {label:"Fri", day:"fri"}, {label:"Sat", day:"sat"},
                      {label:"Sun", day:"sun"}],

    _getTimePickerHelper: function(){
        var self = this;
        if(!self._timePickerHelper)
        {
            self._timePickerHelper = $('<input/>').timepicker({timeFormat: 'g:i a'});
        }
        return self._timePickerHelper;
    },

    _setupDayTimeInfo: function(props)
    {
        var self = this;
        // merge base day of week info with stored restrictions in props
        props.daysOfWeek = _.map(self._baseDaysOfWeek, function(dayInfo){
            return _.extend({
                enabled: !props.day_time_restrictions || props.day_time_restrictions[dayInfo.day]
            }, dayInfo);
        });

        props.isRestrictedToDayTime = props.day_time_restrictions !== undefined;
        props.isRestrictedToDayTimeInverted = (props.isRestrictedToDayTime && props.day_time_restrictions.inverted);
        props.isRestrictedToDayTimeNormal = (props.isRestrictedToDayTime && !props.isRestrictedToDayTimeInverted);
    },

    _configureDayTimeUI: function($container)
    {
        var self = this;
        $container.find(".days_times_restrictions_input_toggle").on("click",function(evt){
            var $targ = $(evt.currentTarget),
                $wrap = $container.find(".days_times_restrictions_input_info"),
                show = $targ.data("value") !== "all";

            $container.find(".days_times_restrictions_input_toggle.tws-segmented-button-selected").removeClass("tws-segmented-button-selected");
            $container.find(".days_times_restrictions_input_toggle.selected").removeClass("selected");
            $targ.addClass("selected tws-segmented-button-selected");

            if(show)
            {
                $wrap.show("slide", {direction:"up", duration:300});
            }
            else
            {
                $wrap.hide("slide", {direction:"up", duration:300});
            }

        });

        $container.find("input.time_window").timepicker({timeFormat: 'g:i a'});
    },

    _getDayTimeInfoFromUI: function($container, fieldId, intoProps, returnRawTime)
    {
        var self=this;
        var $dayTimeRestrictionSetting = $container.find(
            ".days_times_restrictions_input_toggle_"+ fieldId +".selected");

        if($dayTimeRestrictionSetting.length > 0 && $dayTimeRestrictionSetting.data("value") !== "all")
        {
            intoProps.day_time_restrictions = {};
            _.each(["time_restrict_start", "time_restrict_end"], function (timeComponent)
            {
                var timeVal = $container.find(
                    "input[name=days_times_" + timeComponent + "_" + fieldId + "]"
                ).timepicker("getTime");

                if (timeVal)
                {
                    intoProps.day_time_restrictions[timeComponent] = returnRawTime ? timeVal : betterFormatDate(timeVal, "isoTime");
                }
            });

            _.each(self._baseDaysOfWeek, function (dayInfo)
            {
                intoProps.day_time_restrictions[dayInfo.day] = $container.find(
                    "input[name=days_times_day_" + dayInfo.day + "_" + fieldId + "]"
                ).is(":checked");
            });

            if ($dayTimeRestrictionSetting.data("value") === "inverted")
            {
                intoProps.day_time_restrictions.inverted = true;
            }
        }

    },

    _formatDayTimeProps: function(props, default_timezone)
    {
        var self=this,
            resp = "All Days/Times",
            restrictions = props.day_time_restrictions;

        if(restrictions)
        {
            if(restrictions.inverted)
            {
                resp = "All Except: "
            }
            else
            {
                resp = ""
            }

            var days = [];
            _.each(self._baseDaysOfWeek, function(dayInfo){
                if(restrictions[dayInfo.day])
                {
                    days.push(dayInfo.label);
                }
            });

            resp += days.join(", ");

            if(restrictions.time_restrict_start)
            {
                resp += " from " + self._formatTimeVal(restrictions.time_restrict_start);
            }
            if(restrictions.time_restrict_end)
            {
                resp += " until " + self._formatTimeVal(restrictions.time_restrict_end);
            }
            if(restrictions.time_restrict_start || restrictions.time_restrict_end)
            {
                var tz = restrictions.timezone_string || default_timezone;
                if(tz)
                {
                    resp += " " + tz;
                }
            }
        }

        return resp;
    },

    _formatTimeVal: function(timeVal)
    {
        var self = this, $picker = self._getTimePickerHelper();
        $picker.timepicker("setTime", timeVal);
        return $picker.val();
    }

};


App.WorkflowFormVersionMixin = {

    toggleVersionUI: function(evt)
    {
        var self = this;
        self.$(".workflow_form_version_container").slideToggle();
    },

    openVersion: function(evt)
    {
        const self = this;
        let id = $(evt.currentTarget).data("id"),
            target = $(evt.target);

        if (self.handleOpenVersion && !target.hasClass("workflow_form_version_set_live")) {
            self.handleOpenVersion(id);
        }
    },

    showCreateVersion: function (evt) {
        var self = this,
            $el = self.getEl();
        $el.find(".new_workflow_form_version_item").slideToggle();
    },

    createVersion: function(evt)
    {
        var self = this,
            endpoint = self.getCreateVersionEndpoint(),
            params = self.getCreateVersionParams(),
            $el = self.getEl();

        params.version_comments = self.$("textarea[name=new_version_comments]").val();

        $el.html('');
        $el.spin();

        ajax.request(endpoint, params,
            [], function (resp) {
                self._pollForVersionAvailable(resp.new_version_num, undefined, undefined, resp.version_creation_task_id); //????
            }, false, function () {
                setTimeout(
                    function () {
                        self.hide();
                    }, 1200
                );
            }
        );

    },

    _pollForVersionAvailable(newVersionNum, versionId, shouldBeLive, versionCreationTaskId){
        const self = this,
            initialPollInterval = 1200,
            ongoingPollInterval=7000,
            maxPolls=20;
        let pollCount = 0, pollFunction = function (){
            pollCount += 1;
            if(pollCount > maxPolls){
                clearInterval(self.pollTimer);
                raiseAlert("Unable to create/update version. Please contact Twistle Support.");
                return;
            }
            self.checkForVersion(newVersionNum, versionId, shouldBeLive, versionCreationTaskId);
            self.pollTimer = setTimeout(pollFunction, ongoingPollInterval);
        };
        self.pollTimer = setTimeout(pollFunction, initialPollInterval);
    },

    handleVersionCheckResponse: function(versionList, newVersionNum, versionId, shouldBeLive, versionCreationTaskInfo){
        const self = this;

        if(versionCreationTaskInfo?.status == "FAILURE"){
            clearInterval(self.pollTimer);
            raiseAlert(`Unable to create/update version: ${versionCreationTaskInfo.result}
            Please contact Twistle Support.`);
            return;
        }

        versionList.forEach(v => {
            // we cancel polling if the new version exists, or if the version we are updating was successfully
            // set live
            if((newVersionNum && v.version_num === newVersionNum) ||
                (versionId && v.id === versionId && (!shouldBeLive || v.version_live === true))){
                clearInterval(self.pollTimer);
                self.onVersionUpdated(v);
            }
        });
    },

    updateVersionComment: function (evt) {
        var self = this,
            versionId = $(evt.currentTarget).data("id"),
            endpoint = self.getUpdateVersionEndpoint(),
            params = self.getUpdateVersionParamsForVersionId(versionId, false);
        params.version_comments = self.$("textarea[name=version_comments]").val();
        self._updateVersion(versionId, false, endpoint, params)
    },

    setVersionLive: function(evt)
    {
        const self = this;
        let versionId = $(evt.currentTarget).data("id"),
            versionNum = $(evt.currentTarget).data("version"),
            endpoint = self.getUpdateVersionEndpoint(),
            params = self.getUpdateVersionParamsForVersionId(versionId, true);

        raiseConfirm("Are you sure you want to set Version #" + versionNum + " as the live version of this workflow?",
            "Yes",
            function() {
                self._updateVersion(versionId, true, endpoint, params)
            },
            undefined
        );
    },

    _updateVersion: function (versionId, setVersionLive, endpoint, params) {
        var self = this, $el = self.getEl();
        $el.html('');
        $el.spin();

        ajax.request(endpoint, params,
            [], function (resp) {
                self._pollForVersionAvailable(undefined, versionId, setVersionLive);
            }, false, function () {
                setTimeout(
                    function () {
                        self.hide();
                    }, 1200
                );
            }
        );
    },

    toggleMoreVersions: function(evt) {
        const self = this,
            $targ = $(evt.currentTarget);

        if ($targ.html() == "Show More") {
            self.$(".workflow_form_version_container .workflow_form_version_item:not(.new_workflow_form_version_item)").show();
            $targ.html("Show Less");
        } else {
            self.$(".workflow_form_version_container .workflow_form_version_item:not(.selected_version):not(.live)").hide();
            $targ.html("Show More");
        }
    },

    editVersionDescription: function(evt) {
        const self = this,
            $targ = self.$(evt.currentTarget),
            $addEditBar = $targ.parent();

        $addEditBar.hide();
        $addEditBar.siblings(".description").hide();
        $addEditBar.siblings(".version_comments_textarea").show();
        $addEditBar.siblings(".version_comments_controls").show();
    },

    cancelEditVersionDescription: function(evt) {
        const self = this,
            $targ = self.$(evt.currentTarget),
            $cancelSubmitBar = $targ.parent();

        $cancelSubmitBar.hide();
        $cancelSubmitBar.siblings(".description").show();
        $cancelSubmitBar.siblings(".add_edit_description").show();
        $cancelSubmitBar.siblings(".version_comments_textarea").hide();
    }

};

App.WorkflowFormUtils = {
    createDependencySearchBox: function($el, dependencyType, selectedValue, width, attachToBody, allowClear){
        const self = this, isFormSearch = dependencyType === "form";
        initSingleSelectDropdown({
                $select: $el,
                enableSearchDropdown: true,
                limitHeight: false,
                width: width,
                placeholder: isFormSearch ? "Choose a Form" : "Choose a Workflow",
                dropdownCssClass: "form_workflow_select_dropdown",
                selectOnCloseSearchDropdown: false,
                additionalOptions: {
                    dropdownParent: attachToBody ? undefined : self.getEl(),
                    templateResult: function(data) {
                        return $(`<div class="single-wrap">
                                    <div class="single-text" data-value="${data.id}">
                                        ${data.text}
                                    </div>
                                  </div>`);
                    },
                    templateSelection: function (data) {
                        return $(`<div>${data.text}</div>`);
                    },
                    ajax:{
                        url: isFormSearch ? "/form/ListDefinitions" : "/workflows/ListDefinitions",
                        delay: 150,
                        data: function (params) {
                            let newParams = {
                                query: params.term,
                                organization_id: App.account.getDefaultOrg()?.id,
                                per_page: 10
                            }
                            if(isFormSearch){
                                newParams.include_definitions = true;
                            }
                            return newParams;
                        },
                        processResults: function (data, params) {
                            // Transforms the top-level key of the response object from 'items' to 'results'
                            let results;
                            if(isFormSearch){
                                results = data.map(o=> {
                                    return {
                                        id: o.definition.id,
                                        data: o.definition,
                                        text: self.generateDependencyTitle(o.definition)
                                    };
                                });
                            }
                            else {
                                results = data.workflow_definitions.map(o=> {
                                    return {
                                        id: o.id,
                                        data: o,
                                        text: self.generateDependencyTitle(o)
                                    };
                                });
                            }
                            if(allowClear && !params?.term){
                                results = [{
                                   id: -1,
                                   data: null,
                                   text: ""
                                }, ...results];
                            }
                            return {results: results};
                        }
                    }
            }
        });
        if(selectedValue?.id){
            // preselect a value prior to us ever hitting the server
            self.selectDependency($el, selectedValue)
        }
    },
    selectDependency: function($el, selectedValue){
        const self = this;
        let selectedOption = new Option(self.generateDependencyTitle(selectedValue), selectedValue.id, true, true);
        $el.append(selectedOption).trigger('change');
        $el.trigger({
            type: 'select2:select',
            params: {
                data: selectedValue
            }
        });
    },
    generateDependencyTitle: function (definition) {
        var self = this;
        // helper for titles of dependent forms / workflows
        var title = definition.title;
        title += " (v" + definition.version_num + ")";
        if (self.options.showId) {
            title += " (" + definition.id + ")";
        }
        if (definition.version_live) {
            title += ' <div class="live_tag live_tag_green">Live</div>';
        }
        return title;
    },

    openSubDialog: function ($item, itemSelector, containerSelector, dialogClass, params) {
        const self = this;
        if (!self._subDialogs) {
            self._subDialogs = {} // keep track of previously opened sub dialogs
        }
        if (self._subDialogs[containerSelector]) {
            self.removeChildView(self._subDialogs[containerSelector]);
        }
        params.fullyPreventDialog = true;
        params.onCloseCallback = function () {
            self.clearSelectedItem();
        };
        let $subDialog = self.addChildView(App.openDialogView(new App[dialogClass](params)));
        let $container = self.insertSubDialogIntoView(containerSelector, $subDialog);

        if ($item) {
            self.clearSelectedItem();
            $item.addClass("workflow_item_selected");
            self.options.lastSelectedItemInfo = [itemSelector, $item.data("id")];
        }

        if (dialogClass != "FormFieldDefinitionDialogView") {
            self._refreshPreviewToggleTooltip();
        }
        return [$subDialog, $container];
    },

    insertSubDialogIntoView: function(containerSelector, dialogView){
        const self = this, $container = self.$(containerSelector);
        $container.html("");
        dialogView.$el.appendTo($container);
        self._subDialogs[containerSelector] = dialogView;
        $container.scrollTop(0);
        if($container.closest(".tws-workflow-editor-details-pane").length > 0){
            // if we are injecting a sub dialog into the middle/master pane, show the save/cancel actions
            self.$(".tws-workflow-editor-detail-header .tws-workflow-editor-header-pane-actions").show();
        }
        return $container;
    },

    clearSelectedItem: function () {
        var self = this;
        self.$(".workflow_item_selected,.workflow_item_highlighted")
            .removeClass("workflow_item_selected")
            .removeClass("workflow_item_highlighted");
    },
};

App.TemplateValuePickerMixin = {
    toggleTemplateValuePicker: function(evt) {
        const self = this;
        let $picker = self.$(".workflow_template_value_dialog");
        let $subformPickerSection = $picker.find(".template_values_for_submitted_form");
        let $voiceModifierPickerSection = $picker.find(".template_values_for_voice_modifiers");
        let $searchBox = $picker.find("#template_search_input");
        let $targ = $(evt.currentTarget);
        let sectionId = $targ.data("id");
        let type = $targ.data("type");

        $subformPickerSection.hide();
        $voiceModifierPickerSection.show();
        $subformPickerSection.find(".template_value_property").remove();

        // if we are in a form submission handler, bring in the fields from this form.
        if((self.actionType == "submission_actions" || sectionId.indexOf("submission_actions") > -1) && self.formDefs) {
            $subformPickerSection.show();
            const templateId = self.$("select[name=form_definition_id_send_form]").val() || self.formId;
            var formDef = self.formDefs[templateId];
            _.each(formDef.fields||[],function(field){
                $subformPickerSection.append('<div class="ex_form_property template_value_property collapseable_detail_section_row" data-id="subf.'+ field.identifier +'">'+ field.label +'</div>');
            });
        }

        // if configured, populate *all* the form fields
        if($targ.data("show-all") && self.formDefs)
        {
            var $allFieldsPickSection = $picker.find(".template_values_for_all_forms");
            $allFieldsPickSection.show();
            $allFieldsPickSection.children().not('.form_def_title').remove();
            _.each(self.formDefs, function(formDef, formId)
            {
                if(formDef.calculate_total_score)
                {
                    $allFieldsPickSection.append('<div class="ex_form_property template_value_property collapseable_detail_section_row" data-id="score.' + formDef.id + '">' + formDef.abbreviation + ":" + formDef.title + ' Score</div>');
                }
                if(formDef.custom_score_function)
                {
                    $allFieldsPickSection.append('<div class="ex_form_property template_value_property collapseable_detail_section_row" data-id="score.' + formDef.id + '-custom">' + formDef.abbreviation + ":" + formDef.title + ' Custom Score</div>');
                }
                _.each(formDef.fields || [], function (field)
                {
                    $allFieldsPickSection.append('<div class="ex_form_property template_value_property collapseable_detail_section_row" data-id="subf_' + field.identifier + '">' + formDef.abbreviation + ":" + field.label + '</div>');
                });
            });
        }

        let $clinicalKeysPickerSection = $picker.find(".template_values_for_clinical_data_keys");
        self.fillInTemplatePickerClinicalDataKeys($clinicalKeysPickerSection);

        // this is a design-time template picker
        if ($targ.data("design-time")) {
            $picker.addClass("design-time");
            $voiceModifierPickerSection?.hide();
            $clinicalKeysPickerSection?.hide();
        } else {
            $picker.removeClass("design-time");
        }

        $picker.toggle();
        if($picker.is(":visible")) {
            $searchBox.select();
            $searchBox.on('input', function() {
                self.templatePickerChanged($searchBox.val(), $picker);
            });
        }
        if ($picker.is(":visible") || self.templateValuePickerId !== sectionId) {
            if ($targ.hasClass("bottom_left")) {
                $picker.position({
                    my: "right top",
                    at: "right bottom",
                    of: $targ
                });
            } else {
                $picker.position({
                    my: "left top",
                    at: "left bottom",
                    of: $targ
                });
            }
            self.templateValuePickerId = sectionId;
        }
    },

    templatePickerChanged: function(text, $picker) {
        const self = this;
        const $allSections = $picker.find(".collapseable_detail_section");
        const $allValues = $picker.find(".template_value_property");
        if (text.length > 0) {
            // If there's text, expand all sections and narrow results by that text
            $allSections.each(function(_, section) {
                self.$(section).removeClass("collapsed");
            });
            $allValues.each(function(_, templateValue) {
                let $templateValue = self.$(templateValue);
                if ($templateValue.text().toLowerCase().includes(text.toLowerCase())) {
                    $templateValue.show();
                } else {
                    $templateValue.hide();
                }
            });
        } else {
            // If no text, collapse all sections, returning menu to default behavior
            $allValues.each(function(_, templateValue) {
                self.$(templateValue).show();
            });
            $allSections.each(function(_, section) {
                self.$(section).addClass("collapsed");
            });
        }
    },

    templateValueChosen: function (evt) {
        const self = this;
        let $picker = self.$(".workflow_template_value_dialog");
        let $inputField = self.$("input[name=" + self.templateValuePickerId + "],textarea[name=" + self.templateValuePickerId + "]");
        let templateVarId = $(evt.currentTarget).data("id");

        if($inputField?.length !== 1){
            // can it be found by id?
            $inputField = self.$("#" + self.templateValuePickerId);
        }

        if ($inputField) {
            let val = $inputField.val();
            let element = $inputField.get(0);
            // if we have a cursor position, insert value there. otherwise, insert at end of content
            let cursorPosition = val.length;
            if (self.textAreaPositions && Object.prototype.hasOwnProperty.call(self.textAreaPositions, element.id)) {
                cursorPosition = self.textAreaPositions[element.id];
            }
            let templateValue = "{{" + templateVarId + "}}";
            let firstPart = val.substring(0, cursorPosition);
            let lastPart = val.substring(cursorPosition);
            val = firstPart + templateValue + lastPart;
            $inputField.val(val);
            let newCursorPosition = cursorPosition + templateValue.length;
            element.setSelectionRange(newCursorPosition, newCursorPosition);
            $inputField.focus();
        }

        $picker.hide();

        if (self.validateForm) {
            self.validateForm();
        }

        if (self._markContentChanged) {
            self._markContentChanged();
        }
    },

    textAreaBlurred: function(evt) {
        const self = this;
        if (!self.textAreaPositions) {
            self.textAreaPositions = {};
        }
        // save cursor location for potential data insert
        self.textAreaPositions[evt.target.id] = evt.target.selectionStart;
    },

    fillInTemplatePickerClinicalDataKeys: function($clinicalKeysPickerSection) {
        const self = this;
        $clinicalKeysPickerSection.show();
        $clinicalKeysPickerSection.find(".template_value_property").remove();
        new Promise(function(resolve) {
            if (self.options.workflowDef.clinical_data_key_list) {
                resolve();
            } else {
                $clinicalKeysPickerSection.spin();
                ajax.request("/workflows/ListClinicalDataKeys",{
                    organization_id: self.options.workflowDef.organization || self.options.chosenForm?.organization
                }, [], function (resp) {
                    self.options.workflowDef.clinical_data_key_list = resp.clinical_data_key_list;
                    $clinicalKeysPickerSection.spin({stop: true});
                    resolve();
                });
            }
        }).then(() => {
            _.each(self.options.workflowDef.clinical_data_key_list, function (key) {
                $clinicalKeysPickerSection.append(`<div class="ex_form_property template_value_property collapseable_detail_section_row" data-id="clinical.${key}">${key}</div>`);
            });
        });
    }
}