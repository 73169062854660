import $ from 'jquery'
import _ from 'underscore'
import { activateLinks } from 'util/twistle'

import { Strings } from 'util/localize'

import {
    escapeHTML,
} from 'util/string'

import { renderTemplate } from 'util/template_renderer'

import raiseConfirmTemplate from 'common/raise_confirm_dialog'
import DialogView from 'app/views/dialog_view'

// raises an alert dialog
export function raiseAlert(alertContent, buttonText, allowLinks, width, forceButton)
{
    var alertDialog = new DialogView({
                                    width:width || 460,
                                    height:"auto",
                                    pushIfMobile:false,
                                    dialogClass:"dialog_panel alert_dialog_panel",
                                    trueModal:!!forceButton,
                                    overlayClass:"alert_dialog_overlay"
                                    });

    alertContent = (escapeHTML(alertContent) || "").replace(/\n/g, '<br/>');

    if(!buttonText || buttonText.length === 0)
    {
        buttonText = Strings.OK;
    }

    let options = {
        content: alertContent,
        confirmText: buttonText,
        alert: true
    };

    alertDialog.getEl().html(renderTemplate(raiseConfirmTemplate, options));
    if(allowLinks){
        activateLinks(alertDialog.getEl(), "*", undefined, undefined, undefined, undefined, undefined, App.config.get("country_codes"));
    }
    return alertDialog;
}

// raises an confirmation dialog
export function raiseConfirm(confirmContent, buttonText, okCallback, cancelCallBack, escapeContent, destructive, title) {
    var confirmDialog = new DialogView({
                                    width: Math.min($(window).width() - 10, 460),
                                    height:"auto",
                                    pushIfMobile:false,
                                    dialogClass:"dialog_panel alert_dialog_panel",
                                    trueModal:true,
                                    overlayClass:"alert_dialog_overlay",
                                    title: title || ""
                                    });

    if (escapeContent) {
        confirmContent = confirmContent.replace(/\n/g, '<br/>');
    }

    if(!buttonText || buttonText.length === 0)
    {
        buttonText = Strings.OK;
    }

    let options = {
        content: confirmContent,
        cancelText: Strings.cancel,
        confirmText: buttonText,
        destructive: destructive,
        title: title
    };
    confirmDialog.getEl().html(renderTemplate(raiseConfirmTemplate, options));
    confirmDialog.getEl().find(".confirm_button").on("click",function(evt){
        let callBackResult;
        if(okCallback)
        {
            callBackResult = okCallback(evt, confirmDialog);
        }
        if(!callBackResult) {
            confirmDialog.hide();
        }
    });
    confirmDialog.getEl().find(".confirm_cancel_button").on("click",function(evt){
        if(cancelCallBack)
        {
            cancelCallBack(evt, confirmDialog);
        }
        else
        {
            confirmDialog.hide();
        }
    });
    return confirmDialog;
}