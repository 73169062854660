import _ from 'underscore'
import Backbone from 'backbone'

import {
    MobileDevice,
    postRenderImages,
} from 'util/twistle'

import { renderTemplate } from 'util/template_renderer'
import Contact from 'app/models/contact'
import InviteContentMixin from 'app/mixins/invite_content_mixin'
import DialogView from 'app/views/dialog_view'

// renders a dialog to choose a team member to redirect a conversation to
App.MobileAppInstallView = Backbone.View.extend({
    className: "mobile_install_view",
    initialize: function() {
        _.bindAll.apply(_, [this].concat(_.functions(this)));
        this.options = _.extend({}, this.options, {
            android: !!MobileDevice.Android(),
            androidStoreUrl: App.config.get('androidStoreUrl'),
            iPad: !!MobileDevice.iPad(),
            iPhone: !!MobileDevice.iPhone(),
            iOSStoreUrl: App.config.get('iOSStoreUrl')
        });

        const self = this;
        _.extend(self, InviteContentMixin);

        if(App.config.get("provisionalInvitedBy") && !self.options.hideInvitedBy){
            // we are logged in as a provisional user - lets get their account details
            App.account.set("username", App.config.get("provisionalUsername"));
            App.account.fetch({
                success:function(){
                    self.originator_contact = new Contact({username:App.config.get("provisionalInvitedBy")});
                    self.originator_contact.fetch({
                                      success:function(){
                                          self.options.invite_contact = self.originator_contact.toJSON();
                                          _.extend(self.options, self.originator_contact.getInviteInfo());
                                          self.render();
                                      },
                                      error:function(s){
                                          // still load
                                          self.render();
                                      }});
                    self.render();
                },
                error: function(_, response){
                    if (response.responseJSON.error === "NeedsVerification") {
                        window.location = "/account/confirm"
                    }
                }
            });
        }
        else
        {
            self.render();
        }

    },


    events:function(){
        return _.extend({}, DialogView.prototype.events() || {}, {
           "click .launch_app": "launch_app",
           "click .cancel_install_app": "cancel_install_app"
        });
    },

    render: function() {
        var self = this;
        _.bindAll.apply(_, [self].concat(_.functions(self)));

        self.$el.html(renderTemplate("mobile_app_install_template", self.options, App.account.get("locale")));


        if(self.originator_contact && !self.options.hideInvitedBy)
        {
            self.setOriginatorMessage();
        }

        postRenderImages(self.$el);

        return self;
    },

    cancel_install_app: function(){
        var self = this;
        self.hide();
        App.trigger("app:cancel_native_app_load");
    },

    launch_app:function(){
        App.trigger("app:native_app_load");
    },
});
