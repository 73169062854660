import _ from 'underscore'
import $ from 'jquery'
import {
    attachThumbSrc,
    isRenderableVideoType,
    postRenderImages,
    initSingleSelectDropdown
} from 'util/twistle'

import { ajax } from 'util/ajax'
import { timeAgoInWords } from 'util/date'
import { renderTemplate } from 'util/template_renderer'
import Contact from 'app/models/contact'
import DialogView from 'app/views/dialog_view'

App.FormDefinitionSubmissionsDialogView = DialogView.extend({
    className: "formedit_dialog",
    initialize: function(options) {
        const self = this;
        self.options = _.extend({}, self.options, {
            title: "",
            dialogClass:"dialog_panel form_definition_dialog_panel",
            width:Math.min(1200,$(window).width()-50),
            height:$(window).height()-50,
            onAfterReposition:function()
            {
                self.onAfterReposition();
            }
        }, options);
        self.options.alwaysPush = true;
        _.bindAll.apply(_, [self].concat(_.functions(self)));

        DialogView.prototype.initialize.call(self, self.options);
        App.bind("app:form_def_field_saved",self.formFieldSaved,self);
        App.bind("app:response_participants_changed", self.defaultRecipientsChanged,self);
        App.bind("app:form_submitted", self.formSubmitted, self);

        self.render();
    },

    events:function(){
        return _.extend({}, DialogView.prototype.events() || {}, {
            'click .submissions_as_excel': 'submissionsAsExcel',
            'keyup input[name=submissions_name_filter]':'filterSubmissions',
            'click .show_more_submissions': 'loadMoreSubmissions',
            'click .list_item_data_form_submission': 'submissionClicked',
            'click .video_wrap .play_button': 'playVideo',
            'click .video_wrap .collapse_button': 'collapseVideo',
            'click .attach_thumb':'loadAttachment',
            'click .view_submission':'viewSubmission',
            'click .close_attachment_image_browser':'closeAttachmentImageBrowser',
            'click .form_definition_submissions_table,.submissions_filter_wrap':'closeAttachmentImageBrowser',
            'change .attachment_image_browser input[type=range]':'attachmentImageBrowserPositionChanged',
            'click .collapsible': 'toggleAccordion',
            'click .view_all_submissions': 'viewAllSubmissionsForUser',
            'click .view_account_info_link': 'viewExecutionTargetUser' // see App.WorkflowExecutionDisplayMixin
        });
    },

    getRoute: function(){
        let self = this;
        return App.FormDefinitionSubmissionsDialogView.getRouteHelper(
            self.options.formId,
            self.options.filterToSubmissionsForUsername
        );
    },

    render: function() {
        var self = this;
        if(self.rendered){
            // this is needed because sometimes we are added to the stack and other times we are embedded into another
            // view (like the workflow execution history)
            return;
        }
        self.getEl().spin();
        if(self.options.filterToSubmissionsForUsername){
            // need to load details about this contact first
            let contact = new Contact({username: self.options.filterToSubmissionsForUsername});
            contact.fetch({success:function(){
               self.options.filterToContactData = contact.toJSON();
               self.options.renderAllSubmissionsForUser = !self.options.formId;
               self.renderAfterContactFetch();
            }});
        } else {
            self.renderAfterContactFetch();
        }
        self.rendered = true;
    },
    renderAfterContactFetch: function(){
        let self = this;
        if(!self.options.renderAllSubmissionsForUser) {
            ajax.request(
                "/form/View", {
                    form_definition_id: self.options.formId,
                    include_standardized_score_config: true
                },
                [], function (resp) {
                    self.renderForm(resp);
                }, false, function () {
                    setTimeout(
                        function () {
                            self.hide();
                        }, 1200
                    );
                }
            );
        } else if (self.options.renderAllSubmissionsForUser) {
            self.renderAllSubmissionsHeader();
            self.renderAllSubmissionsForUser();
        }

        return self;
    },

    renderForm: function(formDef)
    {
        var self = this;
        // See FormDisplayMixin
        self.formDef = self._baseAugmentForm(formDef);
        self.options.formDef = self.formDef;
        self.getEl().spin({stop:true});
        self.setTitle(self.formDef.title + ": Submissions");
        self.getEl().html(renderTemplate('form_definition_submissions_dialog_template',self.options));
        if(self.options.asTrackingDataForWorkflowExecutionId){
            // hide the header until we have submissions to show
            self.$(".form_definition_submissions_table thead").hide();
        }
        self.fillInSubmissions();
    },

    renderAllSubmissionsHeader: function() {
        const self = this;

        self.getEl().html(
            renderTemplate('form_definition_submissions_dialog_header_template', {
                title: `${self.options.filterToContactData?.formalname}'s Recent Form Submissions`
            })
        );

        let $orderBy = self.$('.order_by');

        initSingleSelectDropdown({
            $select: $orderBy,
            enableSearchDropdown: false,
            limitHeight: false,
            width: 204
        });

        $orderBy.on('select2:select', self.renderAllSubmissionsForUser);
    },

    renderAllSubmissionsForUser: function() {
        const self = this;
        let params = {
            filter_to_user_submissions: self.options.filterToContactData?.id,
            orderby: self.$('.order_by').val() || "name" // Default to alphabetical order
        };
        ajax.request("/form/ListSubmissions", params, [], function(jsonResp) {
            self.getEl().find('.form_definition_submissions_dialog_wrap').remove(); // Remove any existing form submissions on page
            let renderedFormDefs = {};
            _.each(jsonResp.submissions || [], function(submission){
                let formDef = renderedFormDefs[submission.form_definition];
                if (!formDef) {
                    formDef = self._baseAugmentForm(jsonResp.form_definitions[submission.form_definition])
                    renderedFormDefs[submission.form_definition] = formDef;
                    self.getEl().append(renderTemplate('form_definition_submissions_dialog_template', {
                        formDef: formDef,
                        filterToSubmissionsForUsername: self.options.filterToSubmissionsForUsername,
                        totalSubmissions: jsonResp.submissions.filter(s => s.form_definition == submission.form_definition).length,
                        collapsible: true
                    }));
                }
                let $container = self.$(`.form_definition_submissions_table[data-formdefid=${formDef.id}] tbody`);
                $container.append(renderTemplate('form_submission_list_data_item_template', self.augmentSubmission(submission, formDef)));
            });

            if((jsonResp.submissions || []).length === 0){
                self.getEl().append(renderTemplate('no_items_found_template', {
                    itemNotFoundLabel: "No Form Submissions Found"
                }));
            }

            postRenderImages(self.getEl());
        });
    },

    submissionClicked: function(evt){
        const self = this,
            submissionId = $(evt.currentTarget).data("id");
        if(self.options.asTrackingDataForWorkflowExecutionId){
            // allow drill in to edit the actual submission when in this mode
            App.openDialogView(new App.FormSubmissionDialogView({
                formId: self.options.formId,
                submissionId: submissionId,
                asTrackingDataForWorkflowExecutionId: self.options.asTrackingDataForWorkflowExecutionId
            }));
        }
    },

    fillInSubmissions:function(pageNum, $loadMoreButton)
    {
        var self = this,
           params = {
                form_definition_id:self.formDef.id,
                page_num:parseInt(pageNum,10)||1,
                per_page:25,
                name_contains:self.$("input[name=submissions_name_filter]").val(),
                workflow_execution_id: self.options.asTrackingDataForWorkflowExecutionId
           },
           $container = self.$(".form_definition_submissions_table tbody");

        if (self.options.filterToSubmissionsForUsername) {
            params.filter_to_user_submissions = self.options.filterToContactData?.id;
        }
        else if (self.options.currentUserSubmissionsOnly) {
            params.current_user_submissions = true;
        }

        $container.spin({bottom:0});

        ajax.request("/form/ListSubmissions",params,[], function(jsonResp){
            if(!pageNum || pageNum === 1)
            {
                $container.empty();
            }
            if(self.options.asTrackingDataForWorkflowExecutionId && (jsonResp.submissions || []).length > 0){
                // hide the header until we have submissions to show
                self.$(".form_definition_submissions_table thead").show();
            }
            _.each(jsonResp.submissions || [], function(submission){
                $container.append(renderTemplate('form_submission_list_data_item_template', self.augmentSubmission(submission)));
            });
            $container.spin({stop:true});
            postRenderImages($container);

            self.pageNum = pageNum || 1;

            self.$(".submissions_load_more_wrap").toggleClass("hidden", !jsonResp.has_more);

            if(self.options.filterToSubmissionsForUsername){
                self.$(".form_submission_sub_title").text(`${jsonResp.submissions.length} Submissions for ${self.options.filterToContactData?.formalname}`)
            }


        }, false, function(){
            $container.spin({stop:true});
            return false;
        }, $loadMoreButton);
    },

    formSubmitted: function(formDef, submission){
        const self = this, $row = self.$(`.list_item_data_form_submission[data-id=${submission.id}]`);
        if($row.length === 0){
            return;
        }
        $row.find("td").first().spin();
        ajax.request("/form/View", {
            form_definition_id: formDef.id,
            form_submission_id: submission.id,
            attached_to_note_id: submission.last_note
        }, [], function (resp) {
            $row.replaceWith(
                renderTemplate('form_submission_list_data_item_template', self.augmentSubmission(resp.submission, self._baseAugmentForm(formDef)))
            );
        });
    },

    filterSubmissions:function(evt)
    {
        var self = this;
        if(self.submissionFilterTimer)
        {
            clearTimeout(self.submissionFilterTimer);
        }
        self.submissionFilterTimer = setTimeout(function(){
            self.fillInSubmissions(1);
        },230);

    },

    loadMoreSubmissions:function(evt)
    {
        var self = this, nextPage = parseInt(self.pageNum, 10) + 1;

        self.fillInSubmissions(nextPage, $(evt.currentTarget));
    },

    augmentSubmission:function(submission, formDefOverride)
    {
        const self = this;
        let formDef = formDefOverride || self.formDef;

        // see FormDisplayMixin
        submission = self._baseAugmentSubmission(formDef, submission)
        submission.filterToSubmissionsForUsername = self.options.filterToSubmissionsForUsername;
        submission.submission_id = submission.id;
        submission.fields = $.extend(true, [], formDef.fields); // need to clone
        submission.calculate_total_score = formDef.calculate_total_score;

        _.each(submission.fields,function(field){
            var submittedValue = {value: "", field_option_ids: []};
            var outputValues = [];

            if(submission.values && submission.values[field.id])
            {
                submittedValue = submission.values[field.id];
            }

            if (field.options)
            {
                _.each(
                    field.options, function (option)
                    {
                        if (_.contains(submittedValue.field_option_ids, option.id))
                        {
                            outputValues.push(option.label);
                        }
                    }
                );
                field.outputValue = outputValues.join(",");

            }
            else if(field.field_type === "attachments") {
                if(submittedValue.attachments && submittedValue.attachments.length > 0)
                {
                    field.outputHasAttachments = true;

                    field.outputValue = _.map(submittedValue.attachments,function(attachment){
                        attachment.size = isRenderableVideoType(attachment.type) ? "prf" : "std";
                        attachment.thumbURL = attachThumbSrc(attachment, attachment.size, undefined);
                        attachment.field_allowed_types = field.allowed_types;
                        attachment.elapsedDate = timeAgoInWords(submission.moddate);
                        attachment.formattedDate = submission.displayDate;
                        attachment.form_submission_id = submission.id;
                        attachment.edge_size = field.allowed_types === "image/*" ? 200 : 90;
                        attachment.extra_class = isRenderableVideoType(attachment.type) ? "attach_video_element" : "";
                        return attachment;
                    });
                }
            }
            else
            {
                field.outputValue = submittedValue.value;

                if(submittedValue.has_recording)
                {
                    field.outputHasRecording = true;
                }
            }



        });

        return submission;
    },

    submissionsAsExcel:function(evt)
    {
        const self = this;
        let $targ = $(evt.currentTarget)
        let params = {
            form_definition_id: $targ.data("formdefid") || self.formDef.id,
            format: "xls"
        };

        if (self.options.filterToSubmissionsForUsername) {
            params.filter_to_user_submissions = self.options.filterToContactData?.id;
        }
        else if (self.options.currentUserSubmissionsOnly)
        {
            params.current_user_submissions = true;
        }

        window.open("/form/ListSubmissions?" + $.param(params) );
    },

    loadAttachment:function(evt)
    {
        let self = this;
        let $thumbClicked = $(evt.currentTarget);
        let data = $thumbClicked.data();

        if(data.field_allowed_types === "image/*")
        {
            if(!self.attachmentImageBrowserIsOpen)
            {
                var $originals = self.$(".attach_thumb"),
                    $thumbs = $originals.clone().reverse();
                $thumbs.find("img").removeAttr("width"); // drop forced size
                $thumbs.hide().appendTo(self.$(".attachment_image_browser .attachment_image_container"));
                self.$(".attachment_image_browser").fadeIn();
                self.attachmentImageBrowserIsOpen = true;
                evt.stopPropagation();

                self.onAfterReposition();

                var $slider = self.$(".attachment_image_browser input[type=range]");

                $slider.prop("max", $thumbs.length - 1);

                var selectedIndex = $originals.index($thumbClicked) || $thumbs.length - 1;

                $slider.val(selectedIndex);
                self.attachmentImageBrowserPositionChanged(evt);
            }

        }
        else
        {
            ajax.request("/attachment/Info", {seqnum: data.seqnum}, [], function (jsonResp)
            {
                App.trigger("app:show_attachment", data.seqnum, jsonResp,
                    {fromRouter:true} // prevent a nav event
                );
            });
        }
    },

    viewSubmission: function(evt){
        let self = this, $targ = $(evt.currentTarget);
        self.openDialogView(new App.FormSubmissionDialogView(
            {
                submissionId: $targ.data("submission-id"),
                formId: $targ.data("form-definition-id"),
                responseToNoteId: $targ.data("last-note-id")
            })
        );
    },

    attachmentImageBrowserPositionChanged : function(evt)
    {
        var self = this,
            $slider = self.$(".attachment_image_browser input[type=range]"),
            position = $slider.val();

        self.$(".attachment_image_browser .attachment_image_container .attach_thumb").hide();
        var $selected = $(self.$(".attachment_image_browser .attachment_image_container .attach_thumb")[position]);
        $selected.show();

        self.clearSelectedRow();
        var $selectedRow = self.$(".list_item_data_form_submission_" + $selected.data("form_submission_id")).addClass("attachment_browser_selected");
        self.getEl().scrollTo($selectedRow);

    },

    clearSelectedRow:function()
    {
        var self = this;
        self.$(".list_item_data_form_submission.attachment_browser_selected").removeClass("attachment_browser_selected");
    },

    closeAttachmentImageBrowser : function(evt)
    {
        var self = this;
        self.clearSelectedRow();
        if(self.attachmentImageBrowserIsOpen)
        {
            self.$(".attachment_image_browser").fadeOut();
            self.$(".attachment_image_browser .attachment_image_container .attach_thumb").remove();
            self.attachmentImageBrowserIsOpen = false;
        }
    },

    onAfterReposition:function()
    {
        var self = this, $imageBrowser = self.$(".attachment_image_browser");

        $imageBrowser.css({width:self.getEl().width()-200,
                        height: self.getEl().height()-100,
                        "margin-left":150});

        // reset image size
        self.$(".attachment_image_browser .attachment_image_container .attach_thumb img").attr("style", "").css(
            {"max-height": $imageBrowser.height() - 80,"max-width": $imageBrowser.width() - 30}
        );
    },

    toggleAccordion: function(evt) {
        const self = this;
        let $accordion = self.$(evt.currentTarget).parent();
        let $table = $accordion.siblings('.table_container');
        $table.slideToggle(250, () => {
            $accordion.find(".table_toggle").toggleClass("collapsed", !$table.is(":visible"))
        });
    },

    viewAllSubmissionsForUser: function(evt) {
        const self = this;
        let $viewAll = self.$(evt.currentTarget);

        let args = {
            formId: $viewAll.data("form_id"),
            filterToSubmissionsForUsername: $viewAll.data("user_id")
        };

        App.openDialogView(new App.FormDefinitionSubmissionsDialogView(args));
    }
}, {
    getRouteHelper: function(formId, userId){
        let route = "/form_submissions";
        if(userId){
            route += `/user/${userId}`;
        } else {
            route += `/form`;
        }
        route += `/${formId || ""}`;
        return route;
    }
});

// mixin form display helpers
_.extend(App.FormDefinitionSubmissionsDialogView.prototype, App.FormDisplayMixin);
_.extend(App.FormDefinitionSubmissionsDialogView.prototype, App.LanguageSelectionMixin);
_.extend(App.FormDefinitionSubmissionsDialogView.prototype, App.WorkflowExecutionDisplayMixin);