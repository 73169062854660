import _ from 'underscore'
import $ from 'jquery'
import {
    raiseAlert
} from "util/alert_util"

import { ajax } from 'util/ajax'
import { renderTemplate } from 'util/template_renderer'

import SignaturePad from "signature_pad"
import DialogView from 'app/views/dialog_view'

// renders a dialog to choose a team member to redirect a conversation to
App.FormSubmissionsDigSigDialogView = DialogView.extend({
    className: "digsig_dialog",
    initialize: function(options) {
        var drawOnly = App.config.get("isTouch") || $("html").hasClass("touch");
        this.options = _.extend({}, this.options, options, {
            title: drawOnly ? "Please sign in the box below:" : "Please Add Your Signature",
            dialogClass: "attachment_dialog_panel dialog_panel",
            overlayClass: "attachment_dialog_overlay",
            width: Math.max("320",Math.min(550, $(window).width())),
            height: "480",
            drawOnly:drawOnly,
            reload_time: 2500,
            pushIfMobile:true,
        });
        const self = this;

        DialogView.prototype.initialize.call(this, this.options);

        self.render();
    },

    events:function(){
        return _.extend({}, DialogView.prototype.events() || {}, {
            'click .add_signature':'addSignature'
        });
    },

    render: function() {
        var self = this;
        _.bindAll.apply(_, [self].concat(_.functions(self)));
        self.getEl().html(renderTemplate('form_submission_digsig_field_template',self.options));
        if(self.options.hideBackButtonOnStack){
            self.$(".detail_close_button").hide();
        }
        window.setTimeout(function(){self.postRender();},0);
        return self;
    },
    postRender: function(){
        var self = this;
        var $el = self.getEl().find("canvas.pad");

        $el.attr("width", self.getEl().width()-40);

        var canvas = $el[0];

        self.signaturePad = new SignaturePad(canvas);
    },
    addSignature:function(evt)
    {
        var self = this;
        var params;
        var url;

        if (self.options.isRequired && self.signaturePad.isEmpty()) {
            alert("Please draw your signature to continue.");
            return;
        }

        url = "/attachment/Upload";
        var sigData = self.signaturePad.toDataURL();

        if(!sigData || sigData.length === 0)
        {
            raiseAlert("No Signature Present!");
            return;
        }

        sigData = sigData.replace(/^data:image\/(png|jpg);base64,/, "");
        params = {b64_content:sigData,
                  fileName:"signature.png",
                  mimeType:"image/png"
                    }

        ajax.request(url,params,[],function(attachResp)
            {
                self.hide();
                if(self.options.onSign){
                    self.options.onSign(attachResp);
                }
            }

        , true, false, self.$(".add_signature"));
    }
});